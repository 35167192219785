import * as Rellax from 'rellax'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'

gsap.registerPlugin(ScrollTrigger);

export default function common() {
  if(document.getElementsByClassName('rellax').length > 0){
    const rellax = new Rellax('.rellax',{
      center:true
    });
  }

  if(document.getElementsByClassName('rellax-no').length > 0){
    const rellax_no = new Rellax('.rellax-no',{
      center:false
    })
  }

  const headElem = document.getElementById('site-header')
  ScrollTrigger.create({
    trigger: '#container',
    start: 'top top',
    end: 'bottom top',
    toggleClass: {
      targets: headElem,
      className: 'active',
    },
    once: false,
    //markers: true,
  })

  const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
    header: '#site-header',
    offset: 0,
    speed: 1000,
    easing: 'easeInOutQuart',
    speedAsDuration: true,
    durationMin: 1000
    });


  setViewport()
  onVisitPage()
  window.addEventListener('resize', setViewport)

  const openWindowElement = document.querySelectorAll('.openWindow')
  openWindowElement.forEach((el) => {
    el.addEventListener('click', openWindow)
  })
}

function setViewport(){
  const vw = window.innerWidth;
  const vh = window.innerHeight * 0.01
  const head = document.getElementById('site-header')
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  document.documentElement.style.setProperty('--vw', `${vw}px`)
  
  if(head){
    const headSize = head.getBoundingClientRect().height
    document.documentElement.style.setProperty('--head', `${headSize}px`)
  }
}

function openWindow(){
  const el = document.getElementById('availability')
  if(el){
    if(el.classList.contains('active')){
      el.classList.add('fadeout')
      setTimeout(()=>{
        el.classList.remove('fadeout')
        el.classList.remove('active')
      },800)
    }else{
      el.classList.add('active')
    }
  }
}

function onVisitPage(){
  let currentURL = window.location.href;
  let parts = currentURL.split('/');
  let lastPart = parts[parts.length - 2];
  
  const headerNav = document.querySelectorAll('.siteheader__inner__gnavi li a')
  headerNav.forEach((el) => {
    const datavisit = el.dataset.visit
    if(datavisit == lastPart){
      el.classList.add('visit')
    }
  })
}