import { createApp, ref } from 'vue'

export default function usevue(){
  const toggleBool = ref(false)
  const toggle = () => {
    toggleBool.value = !toggleBool.value
  }
  const app = createApp({
    setup(){
      return {
        toggleBool,
        toggle
      }
    }
  })
  app.mount('#site-header')
}