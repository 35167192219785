import { createApp, ref } from 'vue'
import { scCompornent } from './components/scheduleCompornent'

export default function schedule(){
  const app = createApp({
    components: {
      scalender: scCompornent()
    }
  })
  app.mount('#scheduleApp')
}
