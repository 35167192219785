import Swiper from 'swiper'
//import firstview from './firstview'
import gsap from 'gsap';
import { TweenMax, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function index(){  
  //firstview()

  gsap.utils.toArray("#indexThree li").forEach(el => {
    gsap.fromTo(el,
      {},
      {
        y: '0',
        scrollTrigger: {
          trigger: '#indexThree',
          start: "top bottom",
          end: "center center",
          scrub: 2,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
  })

  const indexKussion = document.getElementById('indexKussion')
  gsap.fromTo(indexKussion,
    {},
    {
      marginLeft: '0',
      marginRight: '0',
      borderRadius: '0',
      scrollTrigger: {
        trigger: indexKussion,
        start: "top center-=25%",
        end: "top center+=50%",
        scrub: 2,
        once: false,
        //markers: true,
        invalidateOnRefresh: true,
      }
    }
  )
  gsap.fromTo('#indexKussion .kussion__overlay',
    {},
    {
      opacity: 1,
      scrollTrigger: {
        trigger: indexKussion,
        start: "top top",
        end: "center top",
        scrub: 2,
        once: false,
        //markers: true,
        invalidateOnRefresh: true,
      }
    }
  )
  gsap.fromTo('#indexKussion .kussion__box',
    {},
    {
      opacity: 1,
      scrollTrigger: {
        trigger: indexKussion,
        start: "center center+=25%",
        end: "center center-=25%",
        scrub: 0,
        once: false,
        //markers: true,
        invalidateOnRefresh: true,
      }
    }
  )

}