import * as THREE from 'three';
import fragmentShader from './glsl/fragmentShader.frag';
import vertexShader from './glsl/vertexShader.vert';

export default function background(){
  
  const app = document.getElementById('backgroundCanvas')
  if(app){
    const scene = new THREE.Scene();
    let timer = 0;
    
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 1.5;

    const renderer = new THREE.WebGLRenderer({alpha: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    app.appendChild(renderer.domElement);
    
    const geometry = new THREE.SphereGeometry(10, 256, 256);    
    const material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      wireframe: true,
      uniforms: {
        time: { value: 0 },
        frequency: { value: 2 },
        amplitude: { value: 2.5 },
        edge: { value: 1.15 }
      }
    });

    
    // メッシュの生成
    const sphere = new THREE.Mesh(geometry, material);
    sphere.rotation.z = 5;
    sphere.rotation.y = 3.25;
    scene.add(sphere);

    const clock = new THREE.Clock();
    window.addEventListener('resize', onWindowResize);
    animate();

    function animate() {
      timer += .00005
      firstAnimate(timer)
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

    function firstAnimate(timer){
      material.uniforms.time.value = clock.getElapsedTime();
      if(window.innerWidth < 769){
        material.uniforms.amplitude.value = (window.scrollY / 200 * -.25) - Math.abs(Math.sin(timer));
      }else{
        material.uniforms.amplitude.value = (window.scrollY / 200 * -1) + 2.5 - Math.abs(Math.sin(timer) * 1.25);
      }
      geometry.attributes.position.needsUpdate = true;
    }

    function onWindowResize() {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      camera.aspect = newWidth / newHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(newWidth, newHeight);
    }
  }

}