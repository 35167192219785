import common from './common'
import index from './index'
import background from './background'
import usevue from './usevue'
import schedule from './schedule'
require("fslightbox");

document.addEventListener('DOMContentLoaded', function() {
  usevue()
  schedule()
  common()
  index()
  background()
});